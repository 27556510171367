@tailwind utilities;

@layer utilities {
  @variants responsive {
    h1, .typo-h1 {
      @apply font-bold text-[1.375rem] leading-none tracking-normal;
    }
    h2, .typo-h2 {
      @apply font-normal text-[1.25rem] leading-none tracking-normal;
    }
    h3, .typo-h3 {
      @apply font-bold text-[1.125rem] leading-[1.5625rem] tracking-normal;
    }
    h4, .typo-h4 {
      @apply font-bold text-[1rem] leading-tight tracking-normal;
    }
    .typo-body1 {
      @apply font-normal text-[1rem] leading-normal tracking-normal;
    }
    .typo-body2 {
      @apply font-normal text-[0.875rem] leading-[1.1875rem] tracking-normal;
    }
    .typo-button {
      @apply font-bold text-[0.875rem] leading-[1.25rem] tracking-[0.02em];
    }
    .typo-tag {
      @apply font-semibold text-[0.75rem] leading-none tracking-normal uppercase;
    }
    .typo-helper {
      @apply font-semibold text-[0.75rem] leading-none tracking-normal;
    }
    .typo-label {
      @apply font-normal text-11p leading-[0.75rem] tracking-normal;
    }

    .line-clamp-2 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .line-clamp-3 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .line-clamp-4 {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .line-clamp-5 {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
