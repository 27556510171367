@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

html, body, #root {
  height: 100%;
  background-color: black;
  overflow: hidden;
}

input, button {
  outline: none;
}
